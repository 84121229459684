<template>
    <div>
        <b-card>
            <app-view-table :title="trans('settings-error-list',207)" :isTitleTotal="true" :fields="fields" :items.sync="items" :filters="filters" :isMenuColumn="false" :customColumns="customColumns" @onRowClicked="item=>$set(item, '_showDetails', !item._showDetails)" :customColumnsHeaders="customColumns" :loading="loading_list">
                
                <template v-slot:buttons>
                    <app-button @click="resolve" type="primary">{{trans('settings-fix-error',206)}}</app-button>
                    <app-button @click="sendEmail" type="primary">Send email</app-button><!--
                    <app-button @click="calculatePlanning" type="primary">Calculate planning</app-button>-->
                </template>

                <template v-slot:head_selected>
                        <app-check-box v-model="select_all" value="1" unchecked-value="0" v-on:change="selectAll" />
                </template>

                <template v-slot:selected="cell">
                    <app-check-box v-model="cell.data.item.selected" value="1" unchecked-value="0" v-on:change="selectedChanged(cell.data.item)" />
                </template>

                <template v-slot:row-details="row" >
                    <settings-errors-details-component :itemData="row.data.item" />
                </template>

            </app-view-table>
        </b-card>
    </div>
</template>
<script>
import axios from "@axios";
import SettingsErrorsDetailsComponent from './SettingsErrorsDetailsComponent.vue';
export default {
    
    components: {
        SettingsErrorsDetailsComponent
    },

    props: {

    },

    mounted() {
        this.onCreateThis();
    },

    created() {
    },

    data()  {
        return{
            customColumns: ['selected'],
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                page: 1,
                per_page: 20,
                search: "",
                list_type: 1,
                changed: 0,
            },
            fields:[
                { key: "selected", label: "", tdClass: "py-0 app-local-max-width", visible: true, thStyle: { "min-width": "40px", "max-width": "40px" } },
                { key: "entry_date", label: this.trans('entry-date',182), visible: true, thStyle: { "min-width": "180px", "max-width": "180px" } },
                { key: "route", label: this.trans('settings-route',207), visible: true, thStyle: { "min-width": "130px", width: "100%" } },
                { key: "user_name", label: this.trans('settings-user-name',167), visible: true, thStyle: { "min-width": "220px", "max-width": "220px" } },
                { key: "execution_time", label: this.trans('settings-version-name',207), visible: true, thStyle: { "min-width": "110px", "max-width": "110px" } },
                { key: "source_name", label: this.trans('settings-source-name',207), visible: true, thStyle: { "min-width": "160px", "max-width": "160px" } },
            ],
            items: [],
            selected_ids: [],
            select_all: "0",
            time: null,
        }
    },

    methods: {

        onCreateThis() {
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.changed',function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        },

        getList(){
           this.loading_list = true;
            
           axios
                .post("settings/errors/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                  
                    
                    this.filters.page = res.data.page;

                    this.items = res.data.items;
                    this.select_all = "0";
                    this.selected_ids = [];
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.items.list.forEach(element => {
                        element.selected = "0";
                    });
                });
        },

        resolve(){
            
            this.loading_list = true;

            axios
                .post("settings/errors/resolve", {
                    filters: JSON.stringify(this.filters),
                    ids_error: JSON.stringify(this.selected_ids)
                })
                .then((res) => {                  
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                    this.select_all = false;
                    this.selected_ids = [];
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                });

        },

        sendEmail(){
            axios
                .post("settings/errors/sendEmail", {})
                .then((res) => {                  
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    
                });
        },

        calculatePlanning(){
             axios
                .post("people/profile/action-points/calculatePlanning", {})
                .then((res) => {                  
                    
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    
                });
        },
          
        selectedChanged(item){
            if(item.selected == "1"){
                this.selected_ids.push(item.id_laravel_data_error);
            }
            else if(item.selected == "0") {
                this.selected_ids.splice(this.selected_ids.indexOf(item.id_laravel_data_error), 1);
            }
        },

        selectAll(){
            
            if(this.select_all == 0){
                this.items.list.forEach(element => {
                    element.selected = "0";
                    this.selected_ids.splice(this.selected_ids.indexOf(element.id_laravel_data_error), 1);
                });

            } else {
                this.selected_ids = [];
                this.items.list.forEach(element => {
                    element.selected = "1";
                    this.selected_ids.push(element.id_laravel_data_error);
                });
            }

        },
    },
    
}
</script>
<style>
.app-local-max-width{
    max-width: 20px;
}
</style>